import { ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";
import SimpleInputForm from "../components/UI/SimpleInputForm";
import { isAddress } from "viem";
import { fetchLatestArticle } from "../myModules/api";

const muiTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#016B3A",
    },
    secondary: {
      main: "#04a057",
    },
  },
});

const Affiliate: React.FC = () => {
  const [address, setAddress] = useState("");
  const [latestLink, setLatestLink] = useState("");
  const [specificLink, setSpecificLink] = useState("");
  const handleChange = (e) => {
    setAddress(e.target.value);
  };
  const handleGenerate = async () => {
    const latestArticle = await fetchLatestArticle();
    setLatestLink(`thedailypepe.com/articles/latest?affiliate=${address}`);
    setSpecificLink(
      `thedailypepe.com/articles/${latestArticle.articleId.toString()}?affiliate=${address}`
    );
  };

  return (
    <main className="flex flex-col gap-8 py-8">
      <div className="flex flex-col gap-12">
        <h2 className="flex flex-col items-center text-4xl xl:text-5xl font-semibold tracking-wide">
          Affiliate
        </h2>
        <div className="flex flex-col gap-2  w-full">
          <ThemeProvider theme={muiTheme}>
            <div className="flex flex-col gap-2">
              <label className="font-semibold">Generate affiliate link</label>
              <div className="min-w-[250px] max-w-[400px] w-full">
                <SimpleInputForm
                  name="address"
                  error={!isAddress(address) && address.length > 0}
                  label={"Recipient Address"}
                  placeholder={"Enter The Recipient Address"}
                  value={address}
                  onChange={handleChange}
                />
              </div>
            </div>
          </ThemeProvider>
          <div>
            <button
              onClick={() => handleGenerate()}
              disabled={address.length <= 0 || !isAddress(address)}
              className=" btn  btn-gr text-sm whitespace-nowrap px-6 capitalize disabled:opacity-70 min-w-[150px]">
              generate
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex  gap-2 text-lg">
          <span className="text-gray-600">Latest Article Affiliate Link:</span>{" "}
          <a href="#" className="font-semibold underline hover:text-secondary">
            {latestLink}
          </a>
        </div>
        <div className="flex  gap-2 text-lg">
          <span className="text-gray-600">
            Specific Article Affiliate Link:{" "}
          </span>{" "}
          <a href="#" className="font-semibold underline hover:text-secondary">
            {specificLink}
          </a>
        </div>
      </div>

      {affiliate.map((item, index) => {
        return (
          <div key={index} className="flex flex-col gap-3">
            <h2 className="text-3xl text-primary">{item.question}</h2>
            <p className="text-neutral-1">{item.answer}</p>
          </div>
        );
      })}
    </main>
  );
};

const affiliate = [
  {
    question: "How does our affiliate system work?",
    answer:
      "Anyone can create their own affiliate link with just an Ethereum address. Simply paste your address in the form below, and your link will be generated immediately. Affiliates will receive 5% of the revenue from any Daily Pepe Article NFTs minted via their links.",
  },
  {
    question: "How will affiliates be paid?",
    answer:
      "Affiliates will be paid directly to the Ethereum address they provided in the affiliate link generator. Payments are made immediately when a user mints an article NFT. Payments are made in whatever currency the user used to pay for the article NFT. As of writing this, it is only ETH but may change in the future.",
  },
  {
    question: "What is the difference between the links provided?",
    answer:
      'The "Latest Article" link will always link users to the latest Daily Pepe article, and the "Specific Article" link will always link to the same article.',
  },
  {
    question:
      "How do I get an affiliate link to a specific article that is not the most recent article?",
    answer:
      "Normally, only the most recent article is mintable, so it is pointless to create an affiliate link for an old article. If circumstances occur where this is not the case, you can manually edit the affiliate link to change the article Id in the URL. You can find the article Id in the URL when viewing the relevant article. Be sure to test manually edited links before giving them out.",
  },
];

export default Affiliate;
