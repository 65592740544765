import NumberInput from "../components/UI/NumberInput";
import { useState, useEffect } from "react";
import React from "react";
import SimpleInputForm from "../components/UI/SimpleInputForm";
import { bidFirstEditionNFT, fetchFirstEditionArticleById, fetchLatestFirstEditionArticle, fetchMinBid, fetchWinningAddress, fetchWinningBid, getWallet} from "../myModules/api";
import MintDialogue from "../components/UI/MintDialogue";
import { Address, formatEther, isAddress, parseEther, parseUnits } from "viem";
import Loading from "../components/UI/Loading";
import { FirstEditionArticle } from "../types/apiTypes";
import { Link, useParams } from 'react-router-dom';
import { isPositiveInteger, shortenHexString } from "../myModules/utils";
import Countdown from "react-countdown"

const validDate = (currentDate:bigint, dateEnd:bigint) => {
  return currentDate < dateEnd;
};

const Auction: React.FC = () => {
  const [bid, setBid] = useState<bigint>(0n)
  const [formattedBid, setFormattedBid] = useState<string>('')
  const [winningBid, setWinningBid] = useState<bigint>(0n)
  const [winningAddress, setWinningAddress] = useState<Address>('0x')
  const [minBid, setMinBid] = useState<bigint>(0n)
  const [recipientAddress, setRecipientAddress] = useState<`0x${string}` | "">("");
  const handleChange = (e) => {
    setRecipientAddress(e.target.value);
  };

  const [firstEditionArticle, setFirstEditionArticle] = useState<FirstEditionArticle | null>(null);
  const [statusCode, setStatusCode] = useState(200)

  const { id } = useParams()

  if (typeof id === 'undefined') {
    console.log("error, articleId is undefined this page should 400")
  } else if (!isPositiveInteger(id) && id !== 'latest') {
    console.log("error, articleId is must be a positive integer or latest, this page should 400")
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let article:FirstEditionArticle|null
        if (id === "latest") {
          article = await fetchLatestFirstEditionArticle()
        } else if (typeof id !== 'undefined' && isPositiveInteger(id)) {
          article = await fetchFirstEditionArticleById(BigInt(id))
        } else {
          console.log("malformed article ID in url")
          setStatusCode(400)//bad request
          return
        }
        
        if (article === null) {
          console.log("first edition article with ID: ", id, "not found")
          setStatusCode(404)
          return
        }

        setFirstEditionArticle(article)

      } catch (error) {
        console.error("Error fetching article previews:", error);
        const statusCode = error.hasOwnProperty("statusCode") ? error.statusCode : 999
        setStatusCode(statusCode)
      }
    };

    fetchData();
  }, [id]);

  let once = true
  useEffect(() => {
    if(firstEditionArticle === null) {
      return
    }
    const fetchData = async () => {
      const promises:Promise<any>[] = []
      promises.push(fetchWinningBid(firstEditionArticle.articleId))
      promises.push(fetchWinningAddress(firstEditionArticle.articleId))
      promises.push(fetchMinBid(firstEditionArticle.articleId))
      const [winningBid, winningAddress, minBid, wallet] = await Promise.all(promises)
      setWinningBid(winningBid)
      setWinningAddress(winningAddress)
      setMinBid(minBid)
      try {
        const wallet = await getWallet()
        if (wallet.account) {
          if (once) {
            setRecipientAddress(wallet.account.address)
            once = false
          }
        }
      } catch {}
    }
    fetchData()
  }, [firstEditionArticle])

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [responseState, setResponseState] = useState();
  const [dialogState, setDialogState] = useState(false);
  const toggleDialog = () => {
    setDialogState((prev) => !prev);
  };

  const handleSubmit = async () => {
    toggleDialog();
    setIsLoading(true);
    try {
      setTimeout(async () => {
        if (recipientAddress) {
          if (firstEditionArticle === null) {
            throw new Error("article is null")
          }
          const response = await bidFirstEditionNFT(
            BigInt(firstEditionArticle.articleId), 
            BigInt(bid),
            recipientAddress,
          )
          //@ts-ignore
          setResponseState(response);
          if (response.isError) {
            setIsError(true);
          }
          setIsLoading(false);
        }
      }, 1000);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  };


  if (!firstEditionArticle) {
    return (
      <div className="flex-c min-h-[300px]">
        <Loading />
      </div>
    );
  }

  const noAuction = firstEditionArticle.auctionEnd === 0n ? true : false //this nft is not for auction
  const currentDate = new Date().getTime();
  const auctionOngoing = validDate(
    BigInt(currentDate),
    firstEditionArticle.auctionEnd * 1000n,
  );
  console.log("minBid:", minBid)
  console.log("bid:", bid)
  
  return (
    <main className="py-8">
      <MintDialogue
        state={dialogState}
        toggle={toggleDialog}
        isLoading={isLoading}
        isError={isError}
        setIsError={setIsError}
        response={responseState}
        setResponseState={setResponseState}
      />

      <article className=" flex flex-col gap-8 ">
        <div className="my-8 flex flex-col lg:flex-row gap-8 xl:gap-20">
          <div className="flex-1 flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {/* @ts-ignore */}
              <img src={firstEditionArticle.articleNFTImageURL} alt="nft preview" className="rounded-lg" />
            </div>
          </div>
          <div className="flex-1  lg:pt-12">
            <div className=" max-w-screen-xs mx-auto flex flex-col gap-8">
              <div className="flex flex-col gap-4 text-lg">
                <div className="flex justify-between gap-2 flex-wrap">
                  <span className="font-medium">Winning Bid:</span>
                  <span className="apply-gr-text font-semibold text-xl">
                    {formatEther(winningBid)} ETH
                  </span>
                </div>
                <div className="flex justify-between gap-2 flex-wrap">
                  <span className="font-medium">Winning Address:</span>
                  <span className="apply-gr-text font-semibold text-xl">
                    {shortenHexString(winningAddress)}
                  </span>
                </div>
                <div className="flex justify-between gap-2 flex-wrap">
                  <span className="font-medium">Minimum Bid:</span>
                  <span className="apply-gr-text font-semibold text-xl">
                    {formatEther(minBid)} ETH
                  </span>
                </div>

                <div className="flex justify-between gap-2 flex-wrap mt-6">
                  {/* <span className="font-medium">Recipient Address:</span> */}
                  <div className="flex gap-2 items-center w-full">
                    <SimpleInputForm
                      name="Bid (ETH)"
                      error={bid !== 0n && bid < minBid}
                      label={"Bid (ETH)"}
                      placeholder={"Enter Bid"}
                      value={formattedBid}
                      onChange={(e) => {
                        setFormattedBid(formattedBid)
                        setBid(parseEther(formattedBid))
                      }}
                      hasIcon={true}
                      icon={<a className="cursor-pointer" onClick={()=>{setBid(minBid); setFormattedBid(formatEther(minBid))}}>min</a>}
                      iconPosition="end"
                    />
                  </div>
                </div>

                <div className="flex justify-between gap-2 flex-wrap mt-6">
                  {/* <span className="font-medium">Recipient Address:</span> */}
                  <div className="flex gap-2 items-center w-full">
                    <SimpleInputForm
                      name="address"
                      error={!isAddress(recipientAddress) && String(recipientAddress).length > 0}
                      label={"Recipient Address"}
                      placeholder={"Enter The Recipient Address"}
                      value={recipientAddress}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-c">
                <button
                  onClick={() => handleSubmit()}
                  disabled={
                    BigInt(bid) < minBid ||
                    recipientAddress.length <= 0 ||
                    !isAddress(recipientAddress) ||
                    !auctionOngoing
                  }
                  className={`btn btn-gr disabled:opacity-70 capitalize ${
                    !auctionOngoing && "grayscale"
                  }`}>
                  {noAuction ? "Not For Auction" : auctionOngoing ? "Bid" : "Auction Ended"}
                </button>
              </div>
             <div>
              <div className="flex-c text-3xl">
                    Time Remaining:
              </div>
              <Countdown className="flex-c text-3xl font-semibold" date={new Date(Number(firstEditionArticle.auctionEnd) * 1000)}/>
             </div>
             <div className="flex-c">
                Auction ends: {new Date(Number(firstEditionArticle.auctionEnd) * 1000).toLocaleString()}
             </div>
             <Link className="flex-c text-primary underline" to="/about-auction">How does this work?</Link>
            </div>
          </div>
        </div>
      </article>
    </main>
  );
};

export default Auction