import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { WagmiProvider } from "wagmi";
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";


import "@rainbow-me/rainbowkit/styles.css";

import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Mint from "./pages/Mint";
import About from "./pages/About";
import Archive from "./pages/Archive";
import Auction from "./pages/Auction";
import SingleArticle from "./pages/articles/SingleArticle";
import Affiliate from "./pages/Affiliate";
import AboutAuction from "./pages/About-auction";

import { ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./components/NotFound";
import { config } from "./constants/wagmiConfig";

const queryClient = new QueryClient();

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#016B3A",
    },
    secondary: {
      main: "#04a057",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: ["Inter", "-apple-system", "sans-serif"].join(","),
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          boxSizing: "content-box",
          padding: 3,
          fontSize: "1.125rem",
          color: "#000000 !important",
          backgroundColor: "#000000",
        },
      },
    },
  },
});
const App = () => {
  return (
    <main>
      <ThemeProvider theme={muiTheme}>
        <BrowserRouter>
          <ScrollToTop />{" "}
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <RainbowKitProvider>
                <Navbar /> 
                <div className="mt-60 md:mt-48 pb-12  max-screen min-h-[calc(100dvh-365px)]">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="articles/:id" element={<SingleArticle />} />
                    <Route path="affiliate" element={<Affiliate />} />
                    <Route path="mint/:id" element={<Mint />} />
                    <Route path="auction/:id" element={<Auction />} />
                    <Route path="about" element={<About />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="archive" element={<Archive />} />
                    <Route path="about-auction" element={<AboutAuction />} />
                    {/* 
                    // Temp replacement for the article page data
                    */}
                  </Routes>
                </div>
              <Footer />
              </RainbowKitProvider>
            </QueryClientProvider>
          </WagmiProvider>
        </BrowserRouter>
      </ThemeProvider>
    </main>
  );
};

export default App;
