import { Address } from "viem"

export function isPositiveInteger(value: string|null): boolean {
  // *autistic screatching*
  return value == null ? false : parseInt(value, 10) >= 0 && /^\d+$/.test(value) 
}

export function convertMintPriceToDisplay(price: bigint):number {
  return Number(price) / 10 ** 18
}

export const shortenHexString = (hexString) => {
  // Check if the input string is empty or a valid hexadecimal
  const hexRegex = /^0x[a-fA-F0-9]*$/;
  if (!hexRegex.test(hexString)) {
    return "Invalid hex string";
  }

  // If the input string is empty, return an empty string
  if (hexString === "0x") {
    return "";
  }

  // Get the first 4 and last 4 characters of the hex string
  const firstFour = hexString.substring(0, Math.min(hexString.length, 6)); // Ensure we don't go beyond the string length
  const lastFour = hexString.substring(Math.max(hexString.length - 4, 2)); // Ensure we don't go beyond the string length, and start from the "0x" prefix

  // Construct the shortened string
  const shortenedString = `${firstFour}....${lastFour}`;

  return shortenedString;
}