import { AlgoliaFetcher } from "./algoliaFetcher";
import algoliasearch from 'algoliasearch';
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { Chain } from "viem";
import { getArticlesIndex, getFirstEditionArticlesIndex } from "../../constants/algoliaIndexNames";

const maybeAppId = O.fromNullable(process.env.REACT_APP_ALGOLIA_APP_ID);
const maybeKey = O.fromNullable(process.env.REACT_APP_ALGOLIA_API_KEY);
var appId, apiKey: string;

appId = pipe(
    maybeAppId,
    O.getOrElse(() => { 
        console.warn("No Algolia App ID found in environment variables, using default")
        return "ea6aff4403e697107bf1547384d16837" 
    })
);

apiKey = pipe(
    maybeKey,
    O.getOrElse(() => { 
        console.warn("No Algolia API Key found in environment variables, using default")
        return "LFM20OFTD9" 
    })
);

const algoliaArticleFetchers:{ [key: string]: AlgoliaFetcher} = {} //maps networks to their respective fetchers
const algoliaFirstEditionArticleFetchers:{ [key: string]: AlgoliaFetcher} = {}

const getAlgoliaArticleFetcher = (network:Chain):AlgoliaFetcher => {
    if (algoliaArticleFetchers.hasOwnProperty(network.id)) {
        return algoliaArticleFetchers[network.id.toString()]
    }

    const algoliaIndex = getArticlesIndex(network)
    if (algoliaIndex === null) {
        throw new Error("algolia index for network: "+network.id+" is null")
    }

    const fetcher = new AlgoliaFetcher(
        algoliasearch(
            appId,
            apiKey
        ), 
        algoliaIndex
    )

    algoliaArticleFetchers[network.id.toString()] = fetcher
    return fetcher
}

const getAlgoliaFirstEditionArticleFetcher = (network:Chain):AlgoliaFetcher => {
    if (algoliaFirstEditionArticleFetchers.hasOwnProperty(network.id)) {
        return algoliaFirstEditionArticleFetchers[network.id.toString()]
    }

    const algoliaIndex = getFirstEditionArticlesIndex(network)
    if (algoliaIndex === null) {
        throw new Error("algolia index for network: "+network.id+" is null")
    }

    const fetcher = new AlgoliaFetcher(
        algoliasearch(
            appId,
            apiKey
        ), 
        algoliaIndex
    )

    algoliaFirstEditionArticleFetchers[network.id.toString()] = fetcher
    return fetcher
}

export {
    AlgoliaFetcher,
    getAlgoliaArticleFetcher,
    getAlgoliaFirstEditionArticleFetcher,
}