import { 
  Address, 
  isAddress, 
  createPublicClient,
  createClient,
  Chain,
  http,
  WalletClient,
  Hash,
  PublicClient,
} from 'viem'
import { getArticleNFTAddress, getAuctionAddress, getFirstEditionNFTAddress, getMintControllerAddress } from '../../constants/addressBook'
import mintControllerABI from '../../abis/mintControllerABI'
import articleNFTABI from '../../abis/articleNFTABI'
import firstEditionNFTABI from '../../abis/firstEditionNFTABI'
import auctionABI from '../../abis/auctionABI'
import { Article, FirstEditionArticle, TimeRange, NFTBalance, RawArticleURIData } from '../../types/apiTypes'
import { latestArticleIdCache, articleCache, firstEditionArticleCache, cacheKey } from './cache'

const privateIPFSEndpoint = 'https://api.thedailypepe.com/ipfs/'


export const fetchLatestArticle = async (network:Chain):Promise<Article> => {
  const client = createPublicClient({
    chain: network,
    transport: http()
  })

  //get latest article Id
  var latestId = 0n
  if (latestArticleIdCache.hasOwnProperty(network.id.toString())) {
    latestId = latestArticleIdCache[network.id.toString()]
  } else {
    const data = await client.readContract({
      abi: articleNFTABI,
      address: getArticleNFTAddress(network),
      functionName: 'nextId',
    })
    latestId = (data as bigint) - 1n
  }

  latestArticleIdCache[network.id.toString()] = latestId

  const latestArticle = await fetchArticleById(network, latestId)
  if (latestArticle === null) {
    throw new Error("Latest article should always exist") 
  }
  
  return latestArticle
}

export const fetchArticlePage = async (network:Chain, page: number, articlesPerPage: number):Promise<(Article|null)[]> => {
  //get latest article Id
  const latestArticle = await fetchLatestArticle(network)
  if (latestArticle === null) {
    return []
  }

  const latestId = latestArticle.articleId
  const numArticles = latestId + 1n

  const promises:Promise<Article|null>[] = []
  for (let i = Math.min((page+1)*articlesPerPage, Number(numArticles)-1); i >= page * articlesPerPage; i--) {
    promises.push(fetchArticleById(network, BigInt(i)))
  }

  return await Promise.all(promises)
}

export const fetchArticlePageCount = async (network:Chain, pageSize: number):Promise<number> => {
    //get latest article Id
    const latestArticle = await fetchLatestArticle(network)
    if (latestArticle === null) {
      console.log("failed to get latest article ID via web3")
      return 1
    }
  
    const latestId = latestArticle.articleId
    const numArticles = latestId + 1n
    return Number(numArticles / BigInt(pageSize) + 1n)
}

export const fetchArticleById = async (network:Chain, articleId: bigint):Promise<Article|null> => {
  if (articleCache.hasOwnProperty(cacheKey(articleId, network))) {
    return articleCache[cacheKey(articleId, network)]
  }

  const client = createPublicClient({
    chain: network,
    transport: http()
  })

  try {

  const uri = await client.readContract({
    abi: articleNFTABI,
    address: getArticleNFTAddress(network),
    functionName: 'uri',
    args: [articleId],
  }) as string
  if (uri === '') {
    return null
  }

  const uriData = JSON.parse(await fetchIPFS(uri, false)) as RawArticleURIData
  const promises:Promise<any>[] = []

  promises.push(
    (async () => {
      return fetchIPFS(uriData.articleImage, true)
    })(),
    (async () => {
      return fetchIPFS(uriData.image, true)
    })(),
    (async () => {
      return fetchArticleMintTime(client.chain as Chain, articleId)
    })(),
    (async () => {
      return fetchArticleMintPrice(client.chain as Chain, articleId)
    })(),
  )

  const dataArr = await Promise.all(promises)
  const cacheArticleImageURL = dataArr[0] as string
  const cacheArticleNFTImageURL = dataArr[1] as string
  const mintTimeRange = dataArr[2] as TimeRange
  const mintPrice = dataArr[3] as bigint

  const article:Article = {
    articleDate: uriData.date,
    articleTitle: uriData.title,
    articleImageURL: cacheArticleImageURL,
    articleNFTImageURL: cacheArticleNFTImageURL,
    previewArticleImageURL: cacheArticleImageURL,
    previewArticleNFTImageURL: cacheArticleNFTImageURL,
    articleText: uriData.text,
    articleAuthor: uriData.author,
    mintStart: mintTimeRange.start,
    mintEnd: mintTimeRange.end,
    mintPrice: mintPrice,
    tags: uriData.tags,
    articleId: articleId,

    //raw uri data
    name: uriData.name,
    description: uriData.description,
    external_link: uriData.external_link,
    imageIPFS: uriData.image,
    articleImageIPFS: uriData.articleImage
  }

  articleCache[cacheKey(articleId, network)] = article
  return article

  } catch (error) {
    console.log("error:", error)
    throw error
  }
}

//returns text content or the url of a local image, img must be set to true if retrieving an image
const fetchIPFS = async (_cid:string, img:boolean):Promise<string> => {
  let cid = _cid
  if (_cid.startsWith('ipfs://')) {
    cid = _cid.substring(7)//trim the ipfs:// part of the string so it's just the cid
  }
  const res = await fetch(privateIPFSEndpoint+cid)
  if (!res.ok) {
    throw new Error('error in IPFS request response. status code: '+res.status+'. '+res.statusText)
  }
  if (img) {
    const blob = await res.blob()
    const localURL = URL.createObjectURL(blob)
    if (!localURL) { //this is necessary because bun hasn't implemented createObjectURL yet
      return privateIPFSEndpoint+cid
    } else {
      return localURL
    }
  } else {
    return res.text();
  }
}

const fetchArticleMintTime = async (network:Chain, articleId:bigint):Promise<TimeRange|null> => {
  const client = createPublicClient({
    chain: network,
    transport: http(),
  }) as PublicClient
  const data = await client.readContract({
    abi: articleNFTABI,
    address: getArticleNFTAddress(network),
    functionName: 'issueAvailability',
    args: [articleId],
  }) as [bigint, bigint]
  const timeRangeData:TimeRange = {start: data[0], end: data[1]}

  if (timeRangeData.start === 0n && timeRangeData.end === 0n) {
    return null
  } else if (timeRangeData.start === 0n || timeRangeData.end === 0n) {
    throw new Error('one of the mint time range values is zero, this indicates an admin error on the contract level. Please report this to dev@thedailypepe.com. article Id: '+articleId.toString())
  }

  return timeRangeData
}

const fetchArticleMintPrice = async (network:Chain, articleId:bigint):Promise<bigint|null> => {
  const client = createPublicClient({
    chain: network,
    transport: http(),
  }) as PublicClient
  const data = await client.readContract({
    address: getMintControllerAddress(client.chain),
    abi: mintControllerABI,
    functionName: 'mintPrices',
    args: [articleId],
  })

  const mintPrice = data as bigint
  if (mintPrice === 0n) {
    return null
  }

  return mintPrice
}

export const fetchFirstEditionArticleById = async (network:Chain, articleId:bigint):Promise<FirstEditionArticle|null> => {
  const client = createPublicClient({
    chain: network,
    transport: http(),
  }) as PublicClient

  const uri = await client.readContract({
    abi: firstEditionNFTABI,
    address: getFirstEditionNFTAddress(client.chain),
    functionName: 'tokenURI',
    args: [articleId],
  }) as string

  if (uri === '') {
    return null
  }

  const uriData = JSON.parse(await fetchIPFS(uri, false)) as RawArticleURIData
  const [articleImage, nftImage, auctionEnd] = await Promise.all([
    fetchIPFS(uriData.articleImage, true),
    fetchIPFS(uriData.image, true),
    fetchFirstEditionAuctionEnd(client.chain as Chain, articleId)
  ])

  const article:FirstEditionArticle = {
    articleDate: uriData.date,
    articleTitle: uriData.title,
    articleImageURL: articleImage,
    articleNFTImageURL: nftImage,
    previewArticleImageURL: articleImage,
    previewArticleNFTImageURL: nftImage,
    articleText: uriData.text,
    articleAuthor: uriData.author,
    auctionEnd: auctionEnd,
    tags: uriData.tags,
    articleId: articleId,

    //raw uri data
    name: uriData.name,
    description: uriData.description,
    external_link: uriData.external_link,
    imageIPFS: uriData.image,
    articleImageIPFS: uriData.articleImage
  }

  return article
}

export const fetchLatestFirstEditionArticle = async (network:Chain):Promise<FirstEditionArticle> => {
  const client = createPublicClient({
    chain: network,
    transport: http(),
  }) as PublicClient
  const nextArticleId = await client.readContract({
    abi: firstEditionNFTABI,
    address: getFirstEditionNFTAddress(client.chain),
    functionName: 'nextId',
  }) as bigint
  if (nextArticleId === 0n) {
    console.log("no first edition articles deployed")
    throw new Error("no first edition articles have been deployed yet")
  }
  const latestArticleId = nextArticleId - 1n
  return await fetchFirstEditionArticleById(client.chain as Chain, latestArticleId) as unknown as FirstEditionArticle
}

export const fetchWinningBid = async (network:Chain, articleId:bigint):Promise<bigint> => {
  const client = createPublicClient({
    chain: network,
    transport: http(),
  }) as PublicClient
  const winningBid = await client.readContract({
    abi: auctionABI,
    address: getAuctionAddress(client.chain),
    functionName: 'winningBids',
    args: [articleId],
  }) as bigint

  return winningBid
}

export const fetchWinningAddress = async (network:Chain, articleId:bigint):Promise<Address> => {
  const client = createPublicClient({
    chain: network,
    transport: http(),
  }) as PublicClient
  const winningAddress = await client.readContract({
    abi: auctionABI,
    address: getAuctionAddress(client.chain),
    functionName: 'winningPayoutAddresses',
    args: [articleId],
  }) as Address

  return winningAddress
}

export const fetchMinBid = async (network:Chain, articleId:bigint):Promise<bigint> => {
  const client = createPublicClient({
    chain: network,
    transport: http(),
  }) as PublicClient
  const minBid = await client.readContract({
    abi: auctionABI,
    address: getAuctionAddress(client.chain),
    functionName: 'getMinBid',
    args: [articleId],
  }) as bigint

  return minBid
}

const fetchFirstEditionAuctionEnd = async (network:Chain, articleId:bigint):Promise<bigint> => {
  const client = createPublicClient({
    chain: network,
    transport: http(),
  }) as PublicClient
  const auctionEnd = await client.readContract({
    abi: auctionABI,
    address: getAuctionAddress(client.chain),
    functionName: 'auctionDeadlines',
    args: [articleId],
  }) as bigint

  return auctionEnd
}
