import React, { useState } from "react";
import { BsArrowUp } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import SimpleInputForm from "./UI/SimpleInputForm";
import { ThemeProvider, createTheme } from "@mui/material";
import { isAddress } from "viem";
import { Link, useNavigate } from "react-router-dom";
const muiTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#016B3A",
    },
    secondary: {
      main: "#04a057",
    },
  },
});
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [address, setAddress] = useState("");
  const handleChange = (e) => {
    setAddress(e.target.value);
  };
  const navigate = useNavigate();
  const handleGenerate = () => {
    navigate(`/mint?affiliate=${address}`, { state: { forceRefresh: true } });
    setAddress("");
  };

  return (
    <footer className="bg-neutral-1 text-neutral-11 ">
      <div className="max-screen pt-8 sm:pt-12  pb-4 flex flex-col gap-6">
        <div className=" flex flex-col sm:flex-row gap-y-8 justify-between w-full">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-3">
              <h2 className=" text-xl lg:text-2xl 2xl:text-3xl font-semibold">
                Connect with us
              </h2>
              <div className="flex gap-4 flex-wrap items-center ">
                {socialLinks.map((item, index) => {
                  return (
                    <a
                      key={index}
                      className="text-neutral-12 hover:text-neutral-8 text-xl td-300"
                      href={item.link}
                      aria-label={item.name}>
                      {item.icon}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>

          <Link
            to="/affiliate"
            className=" text-lg lg:text-xl   hover:text-secondary underline underline-offset-2">
            Become an Affiliate
          </Link>
          {/* <div className="flex flex-col gap-2 ">
            <h2 className=" text-xl lg:text-2xl 2xl:text-3xl font-semibold">
              Affiliate
            </h2>
            <div className="flex flex-col gap-2 items-center w-full">
              <ThemeProvider theme={muiTheme}>
                <div className="min-w-[250px] w-full">
                  <SimpleInputForm
                    name="address"
                    error={!isAddress(address) && address.length > 0}
                    label={"Recipient Address"}
                    placeholder={"Enter The Recipient Address"}
                    value={address}
                    onChange={handleChange}
                  />
                </div>
              </ThemeProvider>
              <button
                onClick={() => handleGenerate()}
                disabled={address.length <= 0 || !isAddress(address)}
                className=" btn  btn-gr text-sm whitespace-nowrap px-6 capitalize disabled:opacity-70">
                generate affiliate link
              </button>
            </div>
          </div> */}
        </div>

        <div className="flex justify-between items-center gap-2 border-t border-white/50 pt-6">
          <span className="text-sm text-neutral-10 tracking-wider">
            DailyPepe ©2024
          </span>
          <div className="flex items-center gap-2">
            <div className="flex flex-col gap-1">
              <a
                href="mailto:contact@dailypepe.com"
                className="text-sm lg:text-base 2xl:text-lg underline hover:text-secondary underline-offset-2">
                contact@dailypepe.com
              </a>
            </div>
            <button
              onClick={() => scrollToTop()}
              className="text-white text-xl  hover:text-neutral-1 hover:bg-neutral-11 rounded-full p-2  td-300">
              <BsArrowUp />
            </button>
          </div>
        </div>
      </div>{" "}
    </footer>
  );
};

export default Footer;
interface SocialLink {
  link: string;
  icon: React.ReactElement; // Specify the type for the icon prop
  name: string;
}
const socialLinks: SocialLink[] = [
  // { link: "#", icon: <BsFacebook />, name: "Facebook" },
  // { link: "#", icon: <AiFillInstagram />, name: "Instagram" },
  {
    link: "https://twitter.com/thedailypepe",
    icon: <FaXTwitter />,
    name: "Twitter",
  },
  // { link: "#", icon: <AiFillYoutube />, name: "Youtube" },
  // { link: "#", icon: <FaTiktok />, name: "TikTok" },
  // { link: "#", icon: <FaSnapchatGhost />, name: "Snapchat" },
];
