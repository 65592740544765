import { 
  Address, 
  isAddress, 
  createPublicClient,
  Chain,
  http,
  WalletClient,
  Hash,
  createClient,
} from 'viem'
import mintControllerABI from '../../abis/mintControllerABI'
import { Article, FirstEditionArticle, TimeRange, NFTBalance } from '../../types/apiTypes'
import { getArticleNFTAddress, getAuctionAddress, getMintControllerAddress } from '../../constants/addressBook'
import articleNFTABI from '../../abis/articleNFTABI'
import { getAlgoliaArticleFetcher, getAlgoliaFirstEditionArticleFetcher } from './algoliaFetcherInsances'
import { articleCache, firstEditionArticleCache, latestArticleIdCache, cacheKey } from './cache'
import { fetchArticleById, fetchArticlePage, fetchArticlePageCount, fetchFirstEditionArticleById, fetchLatestArticle, fetchLatestFirstEditionArticle, fetchMinBid, fetchWinningAddress, fetchWinningBid } from './web3Fetcher'
import any from 'promise.any'
import auctionABI from '../../abis/auctionABI'

export const _fetchLatestArticle = async (network:Chain):Promise<Article> => {
  const fetcher = getAlgoliaArticleFetcher(network)
  const promises:Promise<Article>[] = [
    fetcher.getLatestArticle(),
    fetchLatestArticle(network)
  ]

  const article = await any(promises) as Article
  articleCache[cacheKey(article.articleId, network)] = article
  return article
}

export const _fetchArticleData = async (articleId:bigint, network:Chain):Promise<Article|null> => {
  if (articleCache.hasOwnProperty(cacheKey(articleId, network))) {
    return articleCache[cacheKey(articleId, network)]
  }
  const fetcher = getAlgoliaArticleFetcher(network)
  const promises:Promise<Article|null>[] = [
    fetcher.getArticleByCid(articleId.toString()),
    fetchArticleById(network, articleId)
  ]
  const article = await any(promises) as Article|null
  if (article !== null) {
    articleCache[cacheKey(articleId, network)] = article
  }
  return article
}

export const _fetchArticlePage = async (page: number, numArticles: number, network:Chain):Promise<(Article|null)[]> => {
  const fetcher = getAlgoliaArticleFetcher(network)
  const promises:Promise<(Article|null)[]>[] = [
    fetcher.getArticlePage(page, numArticles),
    fetchArticlePage(network, page, numArticles)
  ]

  const articles = await any(promises) as (Article|null)[]
  articles.forEach(article => {
    if (article !== null) {
      articleCache[cacheKey(article.articleId, network)] = article
    }
  })

  return articles
}

export const _fetchArticlePageCount = async (network:Chain, pageSize: number):Promise<number> => {
  const fetcher = getAlgoliaArticleFetcher(network)
  const promises:Promise<number>[] = [
    fetcher.getArticlePageCount(pageSize),
    fetchArticlePageCount(network, pageSize)
  ]

  const pageCount = await any(promises) as number
  return pageCount
}

//balances cannot be cached, because they will likely updated during the session
export const _fetchArticleNFTBalances = async (account:Address, network:Chain):Promise<NFTBalance[]> => {
  const addressList:string[] = []
  const articleIdList:bigint[] = []
  const latestArticle = await _fetchLatestArticle(network)
  if (latestArticle.articleId  > 1000n) {
    console.log("too many articles for optimal performance, consider optimizing the fetchArticleNFTBalances() api")
  }
  for (let i = 0n; i <= latestArticle.articleId; i++) {
    addressList.push(account)
    articleIdList.push(i)
  }

  const client = createPublicClient({
    chain: network,
    transport: http()
  })

  const data = await client.readContract({
    abi: articleNFTABI,
    address: getArticleNFTAddress(network),
    functionName: 'balanceOfBatch',
    args: [addressList, articleIdList],
  })

  const balances = data as bigint[]

  const NFTBalances:NFTBalance[] = []
  for (let i = 0; i < articleIdList.length; i++) {
    if (balances[i] != 0n) {
      NFTBalances.push({
        articleId: articleIdList[i],
        balance: balances[i],
      })
    }
  }

  return NFTBalances
}

//TODO: change the mint function to use the prepareWriteContract functions before writeContract
// type prepareMintArticleNFTResult = {
//   config?: PrepareWriteContractResult,
//   error?: string,
//   isError: boolean,
//   isSuccess: boolean,
// }

// export const _prepareMintArticleNFT = (id:bigint, numMinted:bigint, recipientAccount:`0x${string}`):{}

export const _bidFirstEditionNFT = async (
  walletClient:WalletClient,
  network:Chain,
  id:bigint, 
  senderAccount:Address, 
  recipientAccount:Address, 
  bid:bigint
):Promise<{hash:Hash, error:Error|null, isError:boolean}> => {
  if (!isAddress(recipientAccount)) {
    return {hash: '0x', error: new Error('invalid recipient account: '+recipientAccount+'. not a valid address'), isError: true}
  }
  if (!isAddress(senderAccount)) {
    return {hash: '0x', error: new Error('invalid sender account: '+senderAccount+'. not a valid address'), isError: true}
  }
  const client = createPublicClient({
    chain: network,
    transport: http()
  })

  try {
    const { request } = await client.simulateContract({
      account: senderAccount,
      address: getAuctionAddress(network),
      abi: auctionABI,
      functionName: 'bid',
      chain: network,
      args: [id, recipientAccount],
      value: bid,
    })
    const txHash = await walletClient.writeContract(request)
    return { hash: txHash, error: null, isError: false }
  } catch(err) {
    return { hash: '0x', error: err, isError: true}
  }
}


export const _mintArticleNFT = async (
  id:bigint, 
  numMinted:bigint, 
  recipientAccount:Address, 
  senderAccount:Address,
  network:Chain, 
  walletClient:WalletClient,
  affiliateAccount?:Address,
):Promise<{hash:Hash, error:Error|null, isError:boolean}> => {
  if (!isAddress(recipientAccount)) {
    return {hash: '0x', error: new Error('invalid recipient account: '+recipientAccount+'. not a valid address'), isError: true}
  }

  const client = createPublicClient({
    chain: network,
    transport: http()
  })

  const article = await fetchArticleById(network, id)
  if (!article) {
    return {hash: '0x', error: new Error('failed to get mint price for article id: '+id.toString()), isError: true}
  }

  //mint affiliate
  try {
    if(affiliateAccount) {
      const { request } = await client.simulateContract({
        account: senderAccount,
        address: getMintControllerAddress(network),
        abi: mintControllerABI,
        functionName: 'mintAffiliate',
        chain: network,
        args: [recipientAccount, id, numMinted, affiliateAccount],
        value: article.mintPrice * numMinted
      })
      const txHash = await walletClient.writeContract(request)
      return { hash: txHash, error: null, isError: false }
    } else {//regular mint
      const { request } = await client.simulateContract({
        account: senderAccount,
        address: getMintControllerAddress(network),
        abi: mintControllerABI,
        functionName: 'mint',
        chain: network,
        args: [recipientAccount, id, numMinted],
        value: article.mintPrice * numMinted
      })
      const txHash = await walletClient.writeContract(request)
      return { hash: txHash, error: null, isError: false }
    }
  } catch(err) {
    return { hash: '0x', error: err, isError: true }
  }
  
}

export const _fetchLatestFirstEditionArticle = async (network:Chain):Promise<FirstEditionArticle> => {
  const fetcher = getAlgoliaFirstEditionArticleFetcher(network)
  const promises:Promise<FirstEditionArticle>[] = [
    fetchLatestFirstEditionArticle(network),
    fetcher.getLatestFirstEditionArticle()
  ]

  return any(promises)
}

export const _fetchFirstEditionArticleById = async (network:Chain, articleId:bigint):Promise<FirstEditionArticle|null> => {
  const fetcher = getAlgoliaFirstEditionArticleFetcher(network)
  const promises:Promise<FirstEditionArticle|null>[] = [
    fetchFirstEditionArticleById(network, articleId),
    fetcher.getFirstEditionArticleByCid(articleId.toString())
  ]

  return any(promises)
}

export const _fetchWinningBid = async (network:Chain, articleId:bigint):Promise<bigint> => {
  return fetchWinningBid(network, articleId)
}

export const _fetchWinningAddress = async (network:Chain, articleId:bigint):Promise<Address> => {
  return fetchWinningAddress(network, articleId)
}

export const _fetchMinBid = async (network:Chain, articleId:bigint):Promise<bigint> => {
  return fetchMinBid(network, articleId)
}

