import React, { useState, useEffect, useRef } from "react";
// @ts-ignore
import logo from "assets/logo-white.png";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

const Navbar: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [showMenu]);
  useEffect(() => {
    const button = document.querySelector(".iekbcc0");

    if (button) {
      button.addEventListener("click", handleButtonClick);
    }

    return () => {
      if (button) {
        button.removeEventListener("click", handleButtonClick);
      }
    };
  }, []);

  const handleButtonClick = () => {
    document.body.style.overflow = "auto";
  };
  const navRef = useRef<HTMLDivElement>(null);
  const prevScrollPosRef = useRef(0);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (navRef.current) {
        if (
          window.scrollY > 100 &&
          prevScrollPosRef.current < currentScrollPos
        ) {
          navRef?.current.classList.add("hidden");
        } else {
          navRef?.current.classList.remove("hidden");
        }
      }
      prevScrollPosRef.current = currentScrollPos;
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const today: Date = new Date();
  const formattedDate: string = formatDate(today);
  return (
    <nav className={`  fixed top-0 w-full left-0  bg-[#f5f5f5] z-[50] `}>
      {showMenu && (
        <div className="fixed bg-gray-500/80 w-full h-full z-[40]"></div>
      )}
      <div className="border-b-[3px]  border-primary">
        <div className="py-4 flex items-center justify-between relative z-50  max-screen max-md:border-b bg-[#f5f5f5]">
          <div className="flex items-center gap-16">
            <Link to={"/"} className="flex items-center gap-2">
              <img src={logo} alt="" width={48} className="w-12 lg:w-16" />
            </Link>
            <ul className="flex items-center gap-4 xl:gap-10 2xl:gap-12 lg:text-xl max-md:hidden">
              {navLink.map((item, index) => {
                return (
                  <li key={index}>
                    <NavItem key={index} {...item} setShowMenu={setShowMenu} />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex items-center gap-2">
            <div
              className={`hanson max-lg:text-sm flex items-center gap-2 
                 bg-primary hover:bg-primary/90 text-neutral-11
              td-300   rounded-xl `}>
              <ConnectButton />
            </div>

            <button
              onClick={() => setShowMenu((prev) => !prev)}
              className="md:hidden text-4xl hover:text-secondary">
              {showMenu ? <AiOutlineClose /> : <BiMenuAltRight />}
            </button>
          </div>
        </div>
        <div ref={navRef} className="pb-4">
          <div className="max-screen relative max-md:flex max-md:flex-col gap-4">
            {" "}
            <div className="md:absolute max-md:p-2 max-md:bg-primary max-md:text-white text-center max-md:-mx-4 left-4 xl:left-0 font-medium md:top-1/2 md:-translate-y-1/2 text-neutral-3">
              {formattedDate}
            </div>
            <h1 className="text-5xl lg:text-6xl text-center Chomsky">
              The Daily Pepe
            </h1>
            <div></div>
          </div>
        </div>
      </div>
      <MenuDropdown showMenu={showMenu} setShowMenu={setShowMenu} />
    </nav>
  );
};

export default Navbar;

const navLink = [
  { name: "Mint", to: "mint/latest" },
  { name: "Auction", to: "auction/latest" },
  { name: "Archive", to: "archive" },
  { name: "About", to: "about" },
];
interface MenuDropdownProps {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  showMenu: boolean;
}
const MenuDropdown: React.FC<MenuDropdownProps> = ({
  showMenu,

  setShowMenu,
}) => {
  return (
    <aside
      className={`fixed w-full h-fit  top-0 left-0 pb-20  bg-primary  menu-animation z-[41]  ${
        !showMenu ? "-translate-y-full mt-0 " : "translate-y-0 mt-20 "
      }`}>
      <div className="flex flex-col gap-12 mt-12 text-white">
        <ul className="flex flex-col items-center gap-4 text-xl font-medium px-6">
          {navLink.map((item, index) => {
            return (
              <li
                key={index}
                className="py-2 border-b border-white w-full px-4 text-center">
                <NavItem key={index} {...item} setShowMenu={setShowMenu} />
              </li>
            );
          })}
        </ul>
      </div>
    </aside>
  );
};
interface NavItemProps {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  to: string;
  name: string;
}
const NavItem: React.FC<NavItemProps> = ({ name, to, setShowMenu }) => {
  return (
    <Link
      onClick={() => setShowMenu(false)}
      to={to}
      className="font-medium hover:text-secondary td-300 ">
      {name}
    </Link>
  );
};
