import { 
  Address, 
  Chain,
  Hash,
  WalletClient,
} from 'viem'
import { base, sepolia } from 'viem/chains'
import { Article, NFTBalance, FirstEditionArticle } from '../types/apiTypes'
import { 
  _fetchArticleNFTBalances, 
  _mintArticleNFT,
  _fetchLatestArticle,
  _fetchArticleData,
  _fetchArticlePage,
  _fetchArticlePageCount,
  _fetchLatestFirstEditionArticle,
  _fetchFirstEditionArticleById,
  _fetchWinningBid,
  _fetchWinningAddress,
  _fetchMinBid,
  _bidFirstEditionNFT
} from './internal/apiCore'
import { getWalletClient } from '@wagmi/core'
import { isPositiveInteger } from './utils'
import { config } from '../constants/wagmiConfig'

const defaultNetwork = base

//used for generic deplay api calls, not for calls specific to a single user's account
//check if the user's current network is a valid network, if valid return user's network, if not return default network

export const getWallet = async ():Promise<WalletClient> => {
  return getWalletClient(config)
}

export const getReadOnlyNetwork = ():Chain => {
  if (process.env.REACT_APP_TEST === "TRUE") {
    return sepolia
  } else {
    return defaultNetwork
  }
}

export const fetchLatestArticle = async ():Promise<Article> => {
  const network = getReadOnlyNetwork()
  console.log("network:", network.name)
  return await _fetchLatestArticle(network)
}

export const fetchArticle = async (articleId:bigint|string):Promise<Article|null> => {
  const network = getReadOnlyNetwork()
  if (typeof articleId === 'string' && articleId === 'latest') {
    return _fetchLatestArticle(network)
  } else if (typeof articleId === 'bigint') {
    return _fetchArticleData(articleId, network)
  } else if (typeof articleId === 'string' && isPositiveInteger(articleId)) {
    return _fetchArticleData(BigInt(articleId), network)
  } else {
    throw new Error("malformed article ID: "+articleId)
  }
}


export const fetchArticlePage = async (page:number, numArticles:number) => {
  const network = getReadOnlyNetwork()
  return _fetchArticlePage(page, numArticles, network)
}

export const fetchArticlePageCount = async (pageSize:number):Promise<number> => {
  const network = getReadOnlyNetwork()
  return _fetchArticlePageCount(network, pageSize)
}

export const fetchArticleNFTBalances = async (account:Address):Promise<NFTBalance[]> => {
  const network = await getReadOnlyNetwork()
  return _fetchArticleNFTBalances(account, network)
}

export const mintArticleNFT = async (
  id:bigint,
  numMinted:bigint,
  recipientAccount:Address,
  affiliateAccount?:Address,
):Promise<{hash:Hash, error:Error|null, isError:boolean}> => {
  const network = getReadOnlyNetwork()
  const walletClient = await getWallet()
  const senderAccount = walletClient.account
  if (!senderAccount) {
    throw new Error("failed to get wallet account")
  }
  try {
    return await _mintArticleNFT(id, numMinted, recipientAccount, senderAccount.address, network, walletClient, affiliateAccount)
  } catch (error:any) {
    return {hash: "0x", error, isError:true}
  }
}

export const bidFirstEditionNFT = async (
  id:bigint,
  bid:bigint,
  recipientAccount:Address,
):Promise<{hash:Hash, error:Error|null, isError:boolean}> => {
  const network = getReadOnlyNetwork()
  const walletClient = await getWallet()
  const senderAccount = walletClient.account
  if (!senderAccount) {
    throw new Error("failed to get wallet account")
  }
  try {
    return await _bidFirstEditionNFT(walletClient, network, id, senderAccount.address, recipientAccount, bid)
  } catch (error:any) {
    return {hash: "0x", error, isError:true}
  }
}

export const fetchLatestFirstEditionArticle = async ():Promise<FirstEditionArticle> => {
  const network = await getReadOnlyNetwork()
  return _fetchLatestFirstEditionArticle(network)
}

export const fetchFirstEditionArticleById = async (articleId:bigint):Promise<FirstEditionArticle|null> => {
  const network = await getReadOnlyNetwork()
  return _fetchFirstEditionArticleById(network, articleId)
}

export const fetchWinningBid = async (articleId:bigint):Promise<bigint> => {
  const network = await getReadOnlyNetwork()
  return _fetchWinningBid(network, articleId)
}

export const fetchWinningAddress = async (articleId:bigint):Promise<Address> => {
  const network = await getReadOnlyNetwork()
  return _fetchWinningAddress(network, articleId)
}

export const fetchMinBid = async (articleId:bigint):Promise<bigint> => {
  const network = await getReadOnlyNetwork()
  return _fetchMinBid(network, articleId)
}