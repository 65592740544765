import React, { useState } from "react";
import usePagination from "@mui/material/usePagination";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {
  const { items } = usePagination({
    count: totalPages,
    hideNextButton: true,
    hidePrevButton: true,
    page: currentPage,
    onChange: (event, page) => {
      setCurrentPage(page);
    },
  });
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1n) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="my-8 flex items-center justify-center gap-4 lg:my-16">
      <button
        className="text-3xl h-8 aspect-square bg-primary hover:bg-primary/90 rounded-full text-white flex-c disabled:bg-neutral-9"
        onClick={handlePrevPage}
        disabled={currentPage === 1}>
        <KeyboardArrowLeftIcon />
      </button>
      <nav className="flex justify-between">
        <ul className="flex w-full gap-2 md:gap-6 text-sm text-dimgray-100 sm:text-lg lg:text-xl">
          {items.map(({ page, type, selected, ...item }, index) => {
            let children: React.ReactNode = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (
                <button
                  type="button"
                  style={{
                    fontWeight: selected ? "bold" : undefined,
                    color: selected ? "#016B3A" : undefined,
                  }}
                  {...item}>
                  {page}
                </button>
              );
            } else {
              children = (
                <button type="button" {...item}>
                  {type}
                </button>
              );
            }

            return <li key={index}>{children}</li>;
          })}
        </ul>
      </nav>
      <button
        className="text-3xl h-8 aspect-square bg-primary hover:bg-primary/90 rounded-full text-white flex-c disabled:bg-neutral-9"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}>
        <KeyboardArrowRightIcon />
      </button>
    </div>
  );
};

export default Pagination;
