import {
  mainnet,
  sepolia,
  base,
} from "wagmi/chains";
import { http } from "wagmi";
import { getDefaultConfig } from "@rainbow-me/rainbowkit"

const config = getDefaultConfig({
  appName: "The_Daily_Pepe",
  projectId: "76188143aa891f2eedd2112c16da7d25",
  chains: [base, mainnet, sepolia],
  transports: {
    [base.id]: http('https://base-mainnet.g.alchemy.com/v2/oQeryXfWdKuMhJ_m3wIPoXxASGnOjGDd'),
    [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/oQeryXfWdKuMhJ_m3wIPoXxASGnOjGDd'),
    [sepolia.id]: http('https://eth-sepolia.g.alchemy.com/v2/oQeryXfWdKuMhJ_m3wIPoXxASGnOjGDd'),
  },
});

export {config}