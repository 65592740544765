import { Article } from "../../types/apiTypes"
import { FirstEditionArticle } from "../../types/apiTypes"
import { Chain } from "viem"

//////////////////////////////////////////// CACHE /////////////////////////////////////////////
///////////////////////////////////all keys are article Ids/////////////////////////////////////
export const articleCache: { [key: string]: Article } = {}
export const firstEditionArticleCache: { [key: string]: FirstEditionArticle } = {}
export const latestArticleIdCache: { [key: string]: bigint } = {}
////////////////////////////////////////////////////////////////////////////////////////////////

export const cacheKey = (articleId:bigint, network:Chain):string => {
  return articleId.toString()+"@"+network.id.toString()
}