import { SearchClient, SearchIndex } from 'algoliasearch';
import { Article, FirstEditionArticle } from "../../types/apiTypes";
import { RequestOptions } from '@algolia/transporter';
import { FindObjectOptions } from '@algolia/client-search';


export class AlgoliaFetcher {

    private algoliaSearch: SearchClient;
    private algoliaIndex: SearchIndex; 
    constructor(algoliasearch: SearchClient, indexName: string) {
        this.algoliaSearch = algoliasearch;
        this.initIndex(indexName)
    }

    initIndex(indexName: string): void {
        this.algoliaIndex = this.algoliaSearch.initIndex(indexName);
    }

    async getLatestArticle(): Promise<Article> {
        const res = await this.algoliaIndex.search("", {page: 0, hitsPerPage: 1})
        return parseArticle(res.hits[0])
    }

    async getArticlePage(page: number, numArticles: number): Promise<Article[]> {
        console.log("Fetching articles page:", page, "with", numArticles, "articles");
        const res = await this.algoliaIndex.search("", {
            hitsPerPage: numArticles,
            page: page,
        })
        return res.hits.map(parseArticle)
    }

    getArticlePageCount(pageSize: number): Promise<number> {
        console.log("Fetching page count with page size:", pageSize);
        return this.algoliaIndex.search("", {
            hitsPerPage: pageSize,
            page: 0
        }).then(res => res.nbPages);
    }

    async getArticleByCid(cid: string): Promise<Article|null> {
        const data = await this.algoliaIndex.getObject(cid)
        return parseArticle(data)
    }


    async getLatestFirstEditionArticle(): Promise<FirstEditionArticle> {
        const res = await this.algoliaIndex.search("", {page: 0, hitsPerPage: 1})
        return parseFirstEditionArticle(res.hits[0])
    }

    async getFirstEditionArticlePage(page: number, numArticles: number): Promise<FirstEditionArticle[]> {
        console.log("Fetching articles page:", page, "with", numArticles, "articles");
        const res = await this.algoliaIndex.search("", {
            hitsPerPage: numArticles,
            page: page,
        })
        return res.hits.map(parseFirstEditionArticle)
    }

    getFirstEditionArticlePageCount(pageSize: number): Promise<number> {
        console.log("Fetching page count with page size:", pageSize);
        return this.algoliaIndex.search("", {
            hitsPerPage: pageSize,
            page: 0
        }).then(res => res.nbPages);
    }

    async getFirstEditionArticleByCid(cid: string): Promise<FirstEditionArticle|null> {
        const data = await this.algoliaIndex.getObject(cid)
        return parseFirstEditionArticle(data)
    }
}

const parseArticle = (raw:any):Article => {
    const article = raw as Article
    article.mintStart = BigInt(article.mintStart)
    article.mintEnd = BigInt(article.mintEnd)
    article.mintPrice = BigInt(article.mintPrice)
    article.articleId = BigInt(article.articleId)
    return article
}

const parseFirstEditionArticle = (raw:any):FirstEditionArticle => {
    const article = raw as FirstEditionArticle
    article.auctionEnd = BigInt(article.auctionEnd)
    article.articleId = BigInt(article.articleId)
    return article
}