import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Article, Article as ArticlePreviewProps } from "../../types/apiTypes";
import parse from "html-react-parser";
import Loading from "../../components/UI/Loading";
import { fetchArticle } from "../../myModules/api";
import NotFound from "../../components/NotFound";

const isString = (value: any): value is string => {
  return typeof value === "string";
}

const SingleArticle = () => {
  const [article, setArticle] = useState<ArticlePreviewProps | null>(null);
  const { id } = useParams()
  const [articleId, setArticleId] = useState<bigint>(0n)
  const [notFoundErr, setNotFoundErr] = useState<boolean>(false)
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const affiliateParam: string | null = params.get("affiliate");
  const affiliateString = affiliateParam === null ? "" : `?affiliate=${affiliateParam}`
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isString(id)) {
          const article = await fetchArticle(id)
          setArticle(article)
          if (article !== null) {
            setArticleId(article.articleId)
          } else {
            setNotFoundErr(true)
          }
        } else {
          console.error("Invalid article id:", id);
          setNotFoundErr(true)
        }
      } catch (error) {
        console.error("Error fetching article previews:", error);
        if (error?.status === 404) {
          setNotFoundErr(true)
        }
      }
    };

    fetchData();
  }, []);

  if (notFoundErr) {
    return <NotFound/>
  }
  if (!article) {
    return <div className="flex-c"><Loading /></div>
  }
  return (
    <main className="pt-6">
      {" "}
      <article className="my-8 flex flex-col gap-8 ">
        <div className="flex flex-col gap-2 text-center">
          <h2 className="text-3xl  text-primary font-semibold">
            {article.articleTitle}
          </h2>
        </div>
        <div className="my-8 flex flex-col lg:flex-row gap-8 xl:gap-20">
          <div className="flex-1 flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {/* @ts-ignore */}
              <img src={article.articleImageURL} alt={article.articleTitle} className="rounded-lg"/>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-8 lg:pt-12 ">
            <div className="flex flex-col gap-4 ">
              <div className="flex flex-wrap items-center gap-2">
                {article.tags?.map((item, index) => {
                  return (
                    <div
                      className="bg-primary/50 text-white px-2 py-1 text-xs rounded-md"
                      key={index}>
                      {item}
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm flex items-center gap-1">
                  By{" "}
                  <span className="font-semibold ">
                    {article.articleAuthor}
                  </span>
                </p>
                <span className="text-sm">
                  Published {article.articleDate}
                </span>
              </div>
              <div className="flex flex-col gap-2 text-neutral-1 font-medium tracking-wide leading-relaxed xl:text-lg">

                {parse(article.articleText)}
              </div>
            </div>

            <div className="flex-c">
              <Link to={`/mint/${articleId+affiliateString}`} className="btn btn-gr">
                Mint This Article
              </Link>
            </div>
          </div>
        </div>
      </article>
    </main>
  );
};

export default SingleArticle;
