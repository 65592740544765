import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Article as ArticleProps } from "../types/apiTypes";

interface ArticlePreviewType {
  articlePreview: ArticleProps;
}
const ArticlePreview: React.FC<ArticlePreviewType> = ({ articlePreview }) => {
  // please remove when articleID is available
  // @ts-ignore
  return (
    <article className="my-8 flex flex-col ">
      <div className="flex flex-col gap-2 lg:max-w-[45%]">
        <Link to={`/articles/${articlePreview.articleId}`}>
          <h2 className="text-3xl font-medium text-primary hover:text-secondary">
            {articlePreview.articleTitle}
          </h2>
        </Link>
        <span className="text-sm font-medium">Published {articlePreview.articleDate}</span>

      </div>
      <div className="my-8 flex flex-col lg:flex-row gap-8 xl:gap-20">
        <div className="flex-1 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            {/* @ts-ignore */}
            <img src={articlePreview.articleImageURL}
              alt={articlePreview.articleTitle}
              className="rounded"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-8">
          <div className="flex flex-col gap-2 text-neutral-1 font-medium tracking-wide leading-relaxed xl:text-lg">
            {articlePreview.articleText &&
              parse(articlePreview.articleText)}
          </div>
          <div className="flex-c">
            <Link to={`/mint/${articlePreview.articleId}`} className="btn btn-gr">
              Mint This Article
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ArticlePreview;
