import Pagination from "../components/UI/Pagination";
import { useState, useEffect } from "react";
import React from "react";
import Loading from "../components/UI/Loading";

import { Article } from "../types/apiTypes";
import { Link } from "react-router-dom";

import { fetchArticlePage, fetchArticlePageCount } from "../myModules/api";

const ARTICLES_PER_PAGE = 10;

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };
  return date.toLocaleDateString("en-US", options);
}


const Archive: React.FC = () => {
  const today: Date = new Date();
  const formattedDate: string = formatDate(today);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);
  const [articlePreviews, setArticlePreviews] = useState<(Article|null)[]>([]);

  const updatePage = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const fetchPage = async () => {
      const articlePreviews = await fetchArticlePage(currentPage-1, ARTICLES_PER_PAGE)
      const totalPages = await fetchArticlePageCount(ARTICLES_PER_PAGE);
      setArticlePreviews(articlePreviews);
      setTotalPages(totalPages)
    }
    fetchPage()
  }, [currentPage]);

  //sanitize html if needed for item.articlePreviewText
  if (articlePreviews.length === 0) {
    return (
      <div className="flex-c">
        <Loading />
      </div>
    );
  }
  return (
    <main className="flex flex-col gap-12">
      <h1 className="flex flex-col items-center text-4xl font-semibold tracking-wide">
        Archive <span className="tracking-widest">{formattedDate}</span>
      </h1>
      {/* <div className="flex justify-end">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
            label={"Jump to month and year"}
            views={["month", "year"]}
            maxDate={dayjs()}
          />
        </LocalizationProvider>
      </div> */}
      <div className="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))]  lg:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-8">
        {articlePreviews.map((item, index) => {
          return (
            <SingleNFTPreview key={index} articleNFTPreview={item} />
          );
        })}
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={updatePage}
      />
    </main>
  );
};

export default Archive;

const SingleNFTPreview = ({ articleNFTPreview }) => {
  return (
    <article className="flex flex-col items-center gap-3 h-full">
      <Link
        to={`/articles/${articleNFTPreview.articleId}`}
        className="flex-grow">
        <img
          src={articleNFTPreview.articleNFTImageURL.toString()}
          alt={articleNFTPreview.articleTitle}
          className="object-cover w-full h-full rounded-md"
        />
      </Link>
      <div className="flex flex-col items-center">
        <Link
          to={`/articles/${articleNFTPreview?.articleId}`}
          className="text-neutral-2 hover:text-primary font-semibold text-lg">
          {articleNFTPreview?.articleTitle}
        </Link>
        <span className="text-neutral-2 text-sm">
          {articleNFTPreview?.articleDate}
        </span>
      </div>
    </article>
  );
};

const SingleNFTPreviewSkeleton: React.FC = () => {
  return (
    <article className="flex flex-col items-center gap-3 h-full w-full">
      <div className="bg-gray-300 animate-pulse  h-[200px] w-full"></div>
      <div className="flex flex-col w-full gap-2">
        <div className="h-2.5 bg-gray-200 animate-pulse rounded"></div>
        <div className="h-2.5 bg-gray-200 animate-pulse rounded"></div>
      </div>
    </article>
  );
};