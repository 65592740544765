import React from 'react'

const AboutAuction: React.FC = () => {
  return (
    <main className="flex flex-col gap-8 py-8">
      <div className="flex flex-col gap-3">
        <h2 className="text-3xl text-primary">
          First Edition NFTs
        </h2>
        <p className="text-neutral-1">
          First edition NFT's are one of a kind versions of each article for collectors who want something extra rare. 
          They are ERC721 compliant NFT's stored on the Base network and IPFS.
          The NFT contract is an upgradable proxy. Upgrade permissions will be revoked at some point in the future once we are satisfied with all functionality.<br/>
          The proxy address and source code can be viewed <a className="text-primary underline" href="https://basescan.org/address/0x6022F55D53AE83438161cABd986a147f4F2A21Ac#code">here</a>.<br/>
          The logic address and source code can be viewed <a className="text-primary underline" href="https://basescan.org/address/0x41c4CbF6f34407ED29601E14aA2d9E535b827f06#code">here</a>.
        </p>
      </div>

      <div className="flex flex-col gap-3">
        <h2 className="text-3xl text-primary">
          Auction Details
        </h2>
        <p className="text-neutral-1">
          First editon NFTs will be sold on this site via auction. Anyone can place a bid denominated in ETH as long as the bid is above the minimum. 
          Auctions will last for a predetermined amount of time after which the winner will be locked in. The auction is controlled by our Auction smart contract.
          Not all first edition NFTs will be for sale on this site, but they may be available for resale on secondary markets.<br/>
          The auction address and source code can be viewed <a className="text-primary underline" href="https://basescan.org/address/0x662976b56Efa0516440131e1E8f1a01066119Ab0#code">here</a>.
        </p>
      </div>

      <div className="flex flex-col gap-3">
        <h2 className="text-3xl text-primary">
          Payment, Repayment, and Claims
        </h2>
        <p className="text-neutral-1">
          Upon placing a bid, your funds will be locked in the auction smart contract. There is no way to withdraw a bid after it has been placed.<br/>
          If you are outbid, your funds will be immediately returned to the wallet that placed the bid NOT to the NFT payout address (recipient address) that was specified when you placed your bid.
          Once the auction ends, your NFT can be claimed immediately. The developer will personally distribute the NFTs to winners; but in case he gets hit by a bus, you can claim the rewards yourself in the <a className="text-primary underline" href="https://admin.thedailypepe.com">control panel</a> via the "Payout All" button
        </p>
      </div>
    </main>
  );
};

export default AboutAuction;
