import React, { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

interface NumberInputProps {
  name: string;
  width?: number;
  classes?: string;
  tokensAmount: { [key: string]: number };
  setTokensAmount: Dispatch<SetStateAction<{ [key: string]: number }>>;
}

const NumberInput: React.FC<NumberInputProps> = ({
  name,
  width = 32,
  classes,
  tokensAmount,
  setTokensAmount,
}) => {
  const minAmount = 1
  const handleTokensAmount = (e: ChangeEvent<HTMLInputElement>) => {

    let value = +e.target.value;

    if (Number.isInteger(value)) {
      setTokensAmount({ ...tokensAmount, [e.target.name]: +e.target.value });
    } else {
      setTokensAmount({ ...tokensAmount, [e.target.name]: 0 });
    }
    if (value < minAmount) {
      setTokensAmount({ ...tokensAmount, [e.target.name]: minAmount })
    }
  };

  const handleIncrease = (name: string) => {
    if (!tokensAmount[name]) {
      setTokensAmount({ ...tokensAmount, [name]: 1 });
    } else {
      setTokensAmount({ ...tokensAmount, [name]: tokensAmount[name] + 1 });
    }

  };

  const handleDecrease = (name: string) => {
    if (!tokensAmount[name]) {
      setTokensAmount({ ...tokensAmount, [name]: 0 });
    } else {
      setTokensAmount({ ...tokensAmount, [name]: tokensAmount[name] - 1 });

      if (tokensAmount[name] <= 0) {
        setTokensAmount({ ...tokensAmount, [name]: 0 });
      }
    }

  };

  return (
    <div className="flex max-w-[300px] items-center gap-3">
      <button
        name={name}
        className="flex-c h-12 w-12 flex-shrink-0 bg-primary text-white rounded-md"
        onClick={() => handleDecrease(name)}
        disabled={tokensAmount[name] <= minAmount}>
        <RemoveOutlinedIcon />
      </button>
      <div
        className={`h-12 w-${width} flex-c border border-neutral-10 bg-neutral-12 ${classes}`}>
        <input
          type="tel"
          className={`h-full w-full bg-neutral-12 text-center text-2xl font-semibold text-neutral-3 `}
          value={tokensAmount[name]}
          onChange={(e) => handleTokensAmount(e)}
          name={name}
          placeholder={"100"}
          pattern="[0-9]*"
        />
      </div>
      <button
        name={name}
        className="flex-c h-12 w-12 flex-shrink-0 bg-primary rounded-md"
        onClick={() => handleIncrease(name)}>
        <AddOutlinedIcon sx={{ color: "white" }} />
      </button>
    </div>
  );
};

export default NumberInput;
