import React from "react";
import { Link } from "react-router-dom";
import error404 from "../assets/pepehands.png";
const NotFound = () => {
  return (
    <main className="grid min-h-full place-items-center px-6 py-4 sm:py-8 lg:px-8">
      <div className="flex flex-col items-center gap-2 w-full">
        <img src={error404} alt="404" className="w-full max-w-[500px]" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <div className="mt-2 flex items-center justify-center gap-x-6">
        </div>
      </div>
    </main>
  );
};

export default NotFound;
