import { useMediaQuery, useTheme } from "@mui/material/";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { IoCloseSharp } from "react-icons/io5";
import Loading from "./Loading";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorIcon from "@mui/icons-material/Error";
import { Hash } from "viem"
import { shortenHexString } from "../../myModules/utils";

export const MintDialogue = ({
  state,
  toggle,
  isLoading,
  isError,
  setIsError,
  response,
  setResponseState,
}) => {
  //example txHash: 0x3f2b71b94dfc32ad797e51509aa3e7f0cb535cc55f85cfec0dfe7c9003fa89b1
  //clicking on the txHash should link to:
  //`https://basescan.org/tx/{txHash}`
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Dialog
      open={state}
      variant="standard"
      disableEscapeKeyDown={false}
      disableEnforceFocus
      onClose={toggle}
      maxWidth={"lg"}
      fullScreen={fullScreen}
      fullWidth={true}
      PaperProps={{
        style: {
          borderRadius: "0px",
          background: "transparent",
          margin: 0,
          boxShadow: "none",
        },
      }}>
      <div
        className={`text-1  flex h-full justify-center text-neutral-1  
         md:items-center lg:rounded-lg`}>
        {" "}
        <div className="w-fit min-w-[300px] sm:min-w-[500px] max-w-lg h-fit max-h-[90dvh] rounded-lg  bg-neutral-11 p-8   relative z-[99999] overflow-y-auto ">
          <div className="flex items-start justify-end gap-4">
            <IconButton
              aria-label="close"
              name="share"
              disabled={isLoading}
              onClick={(e) => {
                toggle(e);
                setTimeout(() => {
                  setIsError(false);
                  setResponseState("");
                }, 500);
              }}>
              <span className="text-neutral-1">
                {" "}
                <IoCloseSharp />
              </span>
            </IconButton>
          </div>
          <div className="min-h-[100px] flex-c">
            {isLoading ? (
              <div className="flex flex-col items-center gap-4">
                <Loading />
                <p className="text-neutral-1 text-lg font-medium ">
                  Creating Transaction...
                </p>
              </div>
            ) : isError ? (
              <div className="flex flex-col items-center gap-4">
                <span className="text-6xl text-red-600">
                  <ErrorIcon/>
                </span>
                <div className="text-center">
                  <p className="font-medium text-lg">Error creating transaction:</p>
                  <p className="text-gray-700 text-sm whitespace-pre-wrap break-all">
                    {response?.error.message}
                  </p>
                </div>
              </div>

            ) : (
              <div className="flex flex-col gap-4 items-center">
                <span className="text-xl text-green-600">
                  <TaskAltIcon sx={{ fontSize: "6rem" }} />
                </span>
                <div>
                  <p className="font-medium text-lg">Transaction Submitted!</p>
                  <a>View in block explorer: </a>
                  <a href={`https://basescan.org/tx/${response?.hash}`} target="_blank" className="text-blue-500">{shortenHexString(response?.hash)}</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};


export default MintDialogue;
