import React from 'react'

const About: React.FC = () => {
  return (
    <main className="flex flex-col gap-8 py-8">
      {about.map((item, index) => {
        return (
          <div key={index} className="flex flex-col gap-3">
            <h2 className="text-3xl text-primary">{item.question}</h2>
            <p className="text-neutral-1">{item.answer}</p>
          </div>
        );
      })}
    </main>
  );
};

const about = [
  {
    question: "What is The Daily Pepe?",
    answer:
    `The Daily Pepe is a satirical news publication portraying current events as Pepe the frog memes. We also sell digital collectibles of each publication in the form of NFTs (non-fungible tokens).`
  },
  {
    question: "How often do you publish articles?",
    answer:
    `At the time of writing we plan on making a new article every week and whenever something interesting happens. If we receive enough support we will transition to daily articles. So the name is a bit misleading for now, but “The Weekly to Bi-Weekly Pepe” doesn’t have the same ring to it.`
  },
  {
    question: "Why should I purchase an NFT?",
    answer:
    `To support our work! And to collect a digital piece of history. Every NFT sold supports our writers and artists so they can make more Daily Pepes for you! If you want to support our content but can’t afford it, sharing on social media also helps. <3`
  },
  {
    question: "How do I purchase an NFT?",
    answer:
    `Article NFTs are currently available on the Base network. You will need a cryptocurrency wallet that supports Ethereum based networks (such as Metamask) to purchase one. The price of an NFT is $1 in Ether at the time the article was created. The price of Ether is volatile so it may fluctuate between $0.90 and $1.10. Currently we only accept Ether as payment, but in the future we may accept other cryptos such as PEPE.`
  },
  {
    question: "How are my NFTs stored?",
    answer:
    `NFTs are stored on the Base network in the contract at this address: [ADDRESS]. The contract can be updated for now in case we want to add new features in the near future. Eventually we will lock the contract so it can no longer be upgraded to ensure that The Daily Pepes are permanent. The images and metadata for the NFTs are stored using IPFS, the industry standard for decentralization and censorship resistance.`
  },
];

export default About;
