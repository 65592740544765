import FormControl from "@mui/material/FormControl";

import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import React, { ChangeEvent, ReactNode } from "react";
interface SimpleInputFormProps {
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  name: string;
  value: string;
  type?: string;
  hasPattern?: boolean;
  pattern?: string;
  hasIcon?: boolean;
  icon?: ReactNode;
  iconPosition?: "start" | "end";
  required?: boolean;
  error?: boolean;
}
const SimpleInputForm: React.FC<SimpleInputFormProps> = ({
  placeholder,
  onChange,
  label,
  name,
  value,
  type = "text",
  hasPattern = false,
  pattern = "",
  hasIcon = false,
  icon,
  iconPosition = "end",
  required = false,
  error
}) => {
  return (
    <FormControl
      error={error}
      variant="outlined"
      className="w-full"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <OutlinedInput
        value={value}
        type={type}
        label={label}
        placeholder={placeholder}
        name={name}
        autoComplete={"off"}
        required={required}
        startAdornment={
          (hasIcon && iconPosition == 'start') && (
            <InputAdornment position={iconPosition} sx={{ margin: 0 }}>
              {icon}
            </InputAdornment>
          )
        }
        endAdornment={
          (hasIcon && iconPosition == 'end') && (
            <InputAdornment position={iconPosition} sx={{ margin: 0 }}>
              {icon}
            </InputAdornment>
          )
        }
        inputProps={
          hasPattern
            ? { style: { fontSize: "14px" }, pattern: pattern }
            : { style: { fontSize: "14px" } }
        }
        sx={{
          ".MuiOutlinedInput-root:hover ": {},
          ".MuiOutlinedInput-notchedOutline:hover": {},
          ".MuiInputLabel-root": {
            fontSize: "14px !important",
          },
        }}
      />
    </FormControl>
  );
};
export default SimpleInputForm;
