import { Chain } from "viem"


export const getArticlesIndex = (network:Chain):string|null => {
  switch (network.id) {
    case 1:
      return null//'eth_mainnet_articles'
    case 11155111:
      return 'sepolia_articles'
    case 8453:
      return 'base_articles'
    default:
      return null
  }
}

export const getFirstEditionArticlesIndex = (network:Chain):string|null => {
  switch (network.id) {
    case 1:
      return null//'eth_mainnet_first_edition_articles'
    case 11155111:
      return 'sepolia_first_edition_articles'
    case 8453:
      return 'base_first_edition_articles'
    default:
      return null
  }
}